<template>
  <div class="edit-tree-modal-container">
    <div class="backdrop"></div>
    <div class="modal-container">
      <header>
        <h1>{{ modalDataToUse.headingText }}</h1>
      </header>
      <p class="note">All fields are required</p>
      <p class="error">{{ errorMessage }}</p>
      <div class="existing-member-radio-container" v-if="option < 3">
        <p>Does this member already exist in the tree?</p>
        <input
          type="radio"
          name="existing-member-option"
          :value="true"
          v-model="addingExistingUser"
        />
        Yes
        <br />
        <input
          type="radio"
          name="existing-member-option"
          :value="false"
          v-model="addingExistingUser"
        />
        No
      </div>
      <div
        class="name-input-container"
        v-if="addingExistingUser === false || option === 3"
      >
        <input
          class="name-input"
          type="text"
          placeholder="Name"
          v-model="name"
        />
      </div>
      <div class="existing-member-dropdown" v-if="addingExistingUser === true">
        <select v-model="existingMemberId">
          <option
            v-for="val in Object.keys(allMembersInTreeToIdMap)"
            :key="allMembersInTreeToIdMap[val]"
            :value="allMembersInTreeToIdMap[val]"
          >
            {{ val }}
          </option>
        </select>
      </div>
      <div class="reorder-children-container" v-if="option === 4">
        <div
          v-for="(childName, i) in Object.keys(
            childrenNamesToSortDataMapDuplicate
          )"
          :key="childrenNamesToSortDataMapDuplicate[childName].childId"
          class="children-order-option"
        >
          <p class="child-name">{{ childName }}</p>
          <select
            :model="childrenNamesToSortDataMapDuplicate[childName].orderNumber"
            @change="(e) => updateChildOrder(e, childName)"
          >
            <option
              v-for="(_, j) in Object.keys(childrenNamesToSortDataMapDuplicate)
                .length"
              :key="_ + i + j"
              :value="j"
              :selected="
                j === childrenNamesToSortDataMapDuplicate[childName].orderNumber
              "
            >
              {{ j + 1 }}
            </option>
          </select>
        </div>
      </div>
      <br />
      <div class="select-parent-2-container" v-if="option === 2">
        <label>Parent 2:</label>
        <p class="parent-2-note">
          Note: Current user must have a spouse added in order to add a child
        </p>
        <select v-model="selectedNameFromDropdown">
          <option v-for="val in Object.keys(spouseNamesToIdMap)" :key="val">
            {{ val }}
          </option>
        </select>
      </div>
      <br />
      <footer>
        <button @click="closeEditTreeModal">Close</button>
        <button @click="handleSubmit" :disabled="disableSubmitButton()">
          Submit
        </button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Member from "../models/Member";

class ModalData {
  headingText: string;
  endpoint: string;
  requestMethod: string;

  constructor(headingText: string, endpoint: string, requestMethod: string) {
    this.headingText = headingText;
    this.endpoint = endpoint;
    this.requestMethod = requestMethod;
  }
}

@Component({
  name: "EditTreeModal",
})
export default class EditTreeModal extends Vue {
  @Prop()
  member!: Member;

  @Prop()
  option!: number;

  @Prop()
  closeEditTreeModal!: Function;

  modalData = [
    new ModalData(`Add parent for ${this.member.name}`, `/parent`, `POST`),
    new ModalData(`Add spouse for ${this.member.name}`, `/spouse`, `POST`),
    new ModalData(`Add child for ${this.member.name}`, `/child`, `POST`),
    new ModalData(`Rename ${this.member.name}`, `/edit`, `POST`),
    new ModalData(
      `Reorder children for ${this.member.name}`,
      `/reorder-children`,
      `PUT`
    ),
  ];

  errorMessage = "";

  name = null;
  selectedNameFromDropdown = "";
  disableSubmit = false;
  addingExistingUser = null;
  // ID of existing user being added again in tree
  existingMemberId = null;

  spouseNamesToIdMap: { [key: string]: number } = {};

  childrenNamesToSortDataMap: { [key: string]: { [key: string]: number } } = {};
  childrenNamesToSortDataMapDuplicate: {
    [key: string]: { [key: string]: number };
  } = {};

  // Only populated if adding existing member as another relationship
  allMembersInTreeToIdMap: { [key: string]: number } = {};

  modalDataToUse = this.modalData[this.option];

  @Watch("addingExistingUser")
  addingExistingMemberOptionHandler(value: boolean, oldValue: boolean) {
    if (value && Object.keys(this.allMembersInTreeToIdMap).length === 0) {
      fetch(`${this.$store.state.serverAddress}/existing-members`)
        .then((res) => res.json())
        .then((res) => {
          this.allMembersInTreeToIdMap = res;
        });
    }
    // Added to satisfy compiler
    oldValue;
  }

  mounted() {
    // If adding child, need to get dropdown values for parent 2
    if (this.option === 2) {
      fetch(
        `${this.$store.state.serverAddress}/spouses?memberId=${this.member.id}`
      )
        .then((res) => res.json())
        .then((res) => {
          this.spouseNamesToIdMap = res;
        });
    } else if (this.option === 4) {
      fetch(
        `${this.$store.state.serverAddress}/children?memberId=${this.member.id}`
      )
        .then((res) => res.json())
        .then((res) => {
          const childrenNamesToSortDataMap: {
            [key: string]: { [key: string]: number };
          } = {};
          Object.keys(res).forEach(
            (childName, i) =>
              (childrenNamesToSortDataMap[childName] = {
                orderNumber: i,
                childId: res[childName],
              })
          );
          this.childrenNamesToSortDataMap = { ...childrenNamesToSortDataMap };
          this.childrenNamesToSortDataMapDuplicate = {
            ...childrenNamesToSortDataMap,
          };
        });
    }
  }

  disableSubmitButton() {
    if (this.option === 2 && !this.selectedNameFromDropdown) return true;
    else if (this.option === 4) return false;
    else if (!this.name && !this.existingMemberId) return true;

    return false;
  }

  getAddParentRequestBody() {
    return {
      memberId: this.member.id,
      parentName: this.name,
      parentIsExistingMember: this.addingExistingUser,
      existingMemberId: this.existingMemberId,
    };
  }

  getAddSpouseRequestBody() {
    return {
      memberId: this.member.id,
      spouseName: this.name,
      spouseIsExistingMember: this.addingExistingUser,
      existingMemberId: this.existingMemberId,
    };
  }

  getAddChildRequestBody() {
    return {
      parent1Id: this.member.id,
      parent2Id: this.spouseNamesToIdMap[this.selectedNameFromDropdown],
      childName: this.name,
      childIsExistingMember: this.addingExistingUser,
      existingMemberId: this.existingMemberId,
    };
  }

  getEditMemberRequestBody() {
    return {
      memberId: this.member.id,
      newName: this.name,
    };
  }

  getReorderChildrenRequestBody() {
    const sortedChildrenNames = Object.keys(
      this.childrenNamesToSortDataMapDuplicate
    ).sort((a, b) =>
      this.childrenNamesToSortDataMapDuplicate[a].orderNumber >
      this.childrenNamesToSortDataMapDuplicate[b].orderNumber
        ? 1
        : -1
    );
    return {
      memberId: this.member.id,
      reorderedChildren: sortedChildrenNames.map(
        (name) => this.childrenNamesToSortDataMapDuplicate[name].childId
      ),
    };
  }

  getRequestBody() {
    switch (this.option) {
      case 0:
        return this.getAddParentRequestBody();

      case 1:
        return this.getAddSpouseRequestBody();

      case 2:
        return this.getAddChildRequestBody();

      case 3:
        return this.getEditMemberRequestBody();

      case 4:
        return this.getReorderChildrenRequestBody();
    }
  }

  updateChildOrder(event: Event, childName: string) {
    // Without casting event.target as HTMLSelectElement, the `value` property does not exist
    const newPosition = parseInt((event.target as HTMLSelectElement).value);
    this.childrenNamesToSortDataMapDuplicate[
      childName
    ].orderNumber = newPosition;
  }

  verifyReorderedValues() {
    const orderNumberSet = new Set();
    // Check for duplicates
    // if duplicates exist, order is invalid
    for (const sortData of Object.values(
      this.childrenNamesToSortDataMapDuplicate
    )) {
      if (orderNumberSet.has(sortData.orderNumber)) return false;
      orderNumberSet.add(sortData.orderNumber);
    }

    // If no duplicates exist, order is valid
    return true;
  }

  handleSubmit() {
    if (this.option === 4) {
      if (!this.verifyReorderedValues()) {
        this.errorMessage =
          "One or more order values is invalid, please verify there are no duplicates";
        return;
      } else {
        this.errorMessage = "";
      }
    }
    const options = {
      method: this.modalData[this.option].requestMethod,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.getRequestBody()),
    };
    fetch(
      this.$store.state.serverAddress + this.modalData[this.option].endpoint,
      options
    ).then(() => location.reload());
    this.closeEditTreeModal();
  }
}
</script>

<style lang="scss" scoped>
.edit-tree-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  font-size: 20px;

  .modal-container {
    padding: 10px;
    position: absolute;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    width: 500px;
    // height: 200px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    header {
      h1 {
        border-bottom: 1px solid gray;
      }
    }

    .note {
      color: red;
      font-size: 17px;
    }

    .error {
      color: red;
      font-weight: bold;
      font-size: 14px;
    }

    .name-input {
      font-size: 20px;
    }

    .parent-2-note {
      font-size: 15px;
      font-weight: bold;
      margin: 0;
    }

    .children-order-option {
      display: flex;
      margin-left: 25px;
      width: 250px;
      justify-content: space-between;

      select {
        vertical-align: middle;
        margin-top: 25px;
        height: 20px;
      }
    }

    footer {
      display: flex;
      margin-top: 25px;
      border-top: 1px solid grey;
      padding-top: 10px;
      button {
        margin-left: auto;
        margin-right: 10px;
        height: 25px;
        width: 75px;
        border-radius: 5px;
        background-color: white;
        color: rgb(26, 26, 196);
        border: 1px solid rgb(25, 25, 160);

        + button {
          margin-left: 20px;
          color: white;
          background-color: rgba(39, 39, 194, 0.918);
        }

        &:hover {
          cursor: pointer;

          &:disabled {
            cursor: unset;
          }
        }
      }
    }
  }
}
</style>
