<template>
  <div id="bulk-upload-modal">
    <div id="bulk-upload-modal-body">
      <div id="rendered-tree-container">
        <ul id="tree-root" v-if="receivedData">
          <node
            :member="rootMember"
            :isRoot="true"
            :openEditTreeModal="emptyMethodToPass"
            :rerenderTree="false"
            :key="0"
            :showAll="true"
            :hideAll="false"
          ></node>
        </ul>
      </div>
      <div id="buttons-container">
        <button @click="displayExamplePreview">Show Example</button>
        <button @click="downloadTemplate">Download Template</button>
        <input type="file" @change="uploadFile" ref="bulkUploadXmlFile" />
        <button @click="closeModal">Close</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Member from "../models/Member";
import Node from "./Node.vue";
import BulkUploadConstants from "../constants/BulkUploadModalConstants";

@Component({
  name: "BulkUploadModal",
  components: { Node },
})
export default class BulkUploadModal extends Vue {
  @Prop()
  closeModal!: Function;

  rootMember!: Member;
  receivedData = false;

  // TODO: This is a horrible way to do this, but <node> requires a value for `openEditTreeModal`, which isn't
  // relevant for now for the bulk upload feature. This might be a feature that we implement soon, to allow
  // editing the tree for bulk upload, but for now, since we don't want to allow editing, we pass an empty method
  // instead
  emptyMethodToPass() {}

  getBulkUploadPreview(content: string) {
    fetch(`${this.$store.state.serverAddress}/bulk-upload-preview`, {
      method: "POST",
      body: JSON.stringify({ bulkData: content }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.rootMember = res;
        this.receivedData = true;
      })
      .catch((e) => {
        console.log("Ran into error");
        console.log(e);
      });
  }

  uploadFile() {
    var file = (this.$refs.bulkUploadXmlFile as any).files[0];
    const reader = new FileReader();
    reader.onload = (res) => {
      this.getBulkUploadPreview(res!.target!.result!.toString());
    };
    reader.readAsText(file);
  }

  downloadTemplate() {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:xml/plain;charset=utf-8," +
        encodeURIComponent(BulkUploadConstants.bulkUploadTemplate)
    );
    element.setAttribute("download", "Family Tree Upload Template.xml");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  displayExamplePreview() {
    this.getBulkUploadPreview(BulkUploadConstants.bulkUploadTemplate);
  }
}
</script>

<style lang="scss" scoped>
#bulk-upload-modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: rgba($color: #a8a8a8, $alpha: 0.4);
}

#bulk-upload-modal-body {
  position: absolute;
  border: 1px solid black;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 75vh;
  width: 75vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#rendered-tree-container {
  width: auto;
  border: 5px solid blue;
  margin-bottom: 25px;
  flex: 9;
  overflow: scroll;
}

#buttons-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: fit-content;

  margin: auto;
  gap: 20px;

  button {
    width: fit-content;
    margin: auto;
  }
}

#tree-root {
  width: fit-content;
  margin: auto;
}
</style>