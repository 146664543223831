import Member from "@/models/Member";

export default class RelationshipTreeBuilder {

    static getMembersInRelationshipTree(rootMember: Member, members: Set<Member>): Set<Member> {
        var membersToTotalRelationshipMap: Map<Member, number> = new Map()
        if (members.size < 2) {
            return new Set<Member>();
        }
        var allTreeMembersSet = new Set<Member>();
        for (const member of members) {
            var set = new Set<Member>()
            this._getMembersInRelationshipTree(membersToTotalRelationshipMap, rootMember, member, new Set<Member>())
            allTreeMembersSet = new Set([...allTreeMembersSet, ...set])
        }
        this._cleanUpMap(rootMember, membersToTotalRelationshipMap, members.size);
        return new Set<Member>(membersToTotalRelationshipMap.keys());
    }

    private static _cleanUpMap(rootMember: Member, map: Map<Member, number>, limit: number) {
        if (map.get(rootMember) && map.get(rootMember)! >= limit) {
            for (var child of rootMember.children) {
                if (map.get(child) && map.get(child)! >= limit) {
                    map.delete(rootMember);
                    this._cleanUpMap(child, map, limit);
                    break;
                }
            }
        }
    }

    private static _addMemberToMap(membersToTotalRelationshipMap: Map<Member, number>, member: Member, addedMembers: Set<Member>) {
        var numberMappings = membersToTotalRelationshipMap.get(member);
        membersToTotalRelationshipMap.set(member, numberMappings ? numberMappings + 1 : 1);
        addedMembers.add(member)
    }

    private static _getMembersInRelationshipTree(membersToTotalRelationshipMap: Map<Member, number>, rootMember: Member, memberToFind: Member, addedMembers: Set<Member>) {
        if (memberToFind == rootMember) {
            this._addMemberToMap(membersToTotalRelationshipMap, rootMember, addedMembers)
            return;
        }

        for (var child of rootMember.children) {
            this._getMembersInRelationshipTree(membersToTotalRelationshipMap, child, memberToFind, addedMembers)
            if (addedMembers.has(child)) {
                this._addMemberToMap(membersToTotalRelationshipMap, rootMember, addedMembers)
                break;
            };
        }
    }
}