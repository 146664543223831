const bulkUploadTemplate: string =
    `
    <!--
        Instructions:
            - Each person to be added to the tree must be represented by a <familyMember> tag.
            - Every child being added must have a <secondParent> being added, where the name for the <secondParent>
              matches the name of the spouse of the parent that belongs to the tree by blood
            - The <spouse> is optional, but required if the <familyMember> has children defined
    -->

    <familyMember>
        <name>GrandFather</name>
        <spouse>GrandMother</spouse>
        <children>
            <familyMember>
                <secondParent>GrandMother</secondParent>
                <name>Father</name>
                <spouse>Mother</spouse>
                <children>
                    <familyMember>
                        <secondParent>Mother</secondParent>
                        <name>Sister</name>
                        <spouse>Brother in Law</spouse>
                    </familyMember>
                    <familyMember>
                        <secondParent>Mother</secondParent>
                        <name>Self</name>
                        <spouse>Wife</spouse>
                    </familyMember>
                </children>
            </familyMember>
            <familyMember>
                <secondParent>GrandMother</secondParent>
                <name>Aunt 1</name>
                <spouse>Uncle 1</spouse>
            </familyMember>
            <familyMember>
                <secondParent>GrandMother</secondParent>
                <name>Uncle 2</name>
                <spouse>Aunt 2</spouse>
                <children>
                    <familyMember>
                        <secondParent>Aunt 2</secondParent>
                        <name>Cousin 1</name>
                        <spouse>Cousin in Law 1</spouse>
                    </familyMember>
                    <familyMember>
                        <secondParent>Aunt 2</secondParent>
                        <name>Cousin 2</name>
                    </familyMember>
                </children>
            </familyMember>
        </children>
    </familyMember>`

export default {
    bulkUploadTemplate
}