<template>
  <div id="container">
    <p id="no-member-selected-container">
      Please select at least 2 members to view relationship tree. You can select
      a member by hovering over a member.
    </p>
    <div v-if="getMembersToShowRelationshipFor().size > 0">
      <show-relationship-panel-entry
        v-for="member in getMembersToShowRelationshipFor()"
        :key="member.id"
        :member="member"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import ShowRelationshipPanelEntry from "./ShowRelationshipPanelEntry.vue";
import Member from "../models/Member";
import RelationshipTreeBuilder from "../utilities/RelationshipTreeBuilder";

@Component({
  name: "ShowRelationshipPanel",
  components: {
    ShowRelationshipPanelEntry,
  },
})
export default class ShowRelationshipPanel extends Vue {
  @Watch("$store.state.membersToDrawRelationshipFor")
  buildRelationshipTree() {
    var allTreeMembersSet =
      RelationshipTreeBuilder.getMembersInRelationshipTree(
        this.$store.state.rootMember,
        this.getMembersToShowRelationshipFor()
      );
    this.$store.commit("setMembersInRelationshipTree", allTreeMembersSet);
  }

  getMembersToShowRelationshipFor(): Set<Member> {
    return this.$store.state.membersToDrawRelationshipFor;
  }
}
</script>


<style lang="scss" scoped>
#container {
  height: 250px;
  width: 200px;
  border: 1px solid black;
  position: fixed;
  bottom: 25px;
  left: 25px;
  padding: 10px;
  box-shadow: -3px 3px black;
  background-color: white;
  z-index: 99999;
}
</style>