<template>
  <li class="node-container">
    <div
      class="node"
      :class="{
        'selected-for-relationship-tree': isMemberInRelationshipTree,
        'highlight-branch': shouldHighlightBranch && isMemberInRelationshipTree,
        'highlight-bottom-stem':
          isMemberInRelationshipTree && hasChildInRelationshipTree(),
      }"
      :ref="buildCurrentNodeId(member.name)"
    >
      <div class="vertical-bar top" v-if="!isRoot"></div>
      <div
        class="vertical-bar bottom"
        v-if="!hideChildren && member.children.length > 0"
      ></div>

      <div class="spouses-container">
        <member-node
          :id="member.name + '-member-node'"
          :member="member"
          :openEditTreeModal="openEditTreeModal"
          :toggleHideChildren="toggleHideChildren"
          :isMemberInRelationshipTree="isMemberInRelationshipTree"
        />
        <div
          v-if="member.spouses.length > 0"
          class="spouse-connector-bar"
          :style="{ '--spouse-connector-bar-width': spouseConnectorBarWidth }"
        ></div>
        <div
          class="spouse-container"
          v-for="spouse in member.spouses"
          :key="spouse.id"
        >
          <member-node
            :member="spouse"
            :openEditTreeModal="openEditTreeModal"
            :isSpouse="true"
          ></member-node>
        </div>
      </div>
    </div>
    <ul
      v-if="!hideChildren && member.children.length > 0"
      :class="'tree-children ' + sanitizeString(member.name) + '-child'"
      :id="sanitizeString(member.name) + '-tree-children'"
    >
      <node
        v-for="child in member.children"
        :key="sanitizeString(child.name)"
        :ref="buildChildNodeId(child.name)"
        :member="child"
        :isRoot="false"
        :openEditTreeModal="openEditTreeModal"
        :rerenderTree="rerenderTree"
        :showAll="showAll"
        :hideAll="hideAll"
        :shouldHighlightBranch="isMemberInRelationshipTree"
      />
    </ul>
  </li>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Member from "../models/Member";
import MemberNode from "./MemberNode.vue";

@Component({
  name: "Node",
  components: {
    MemberNode,
  },
})
class Node extends Vue {
  @Prop()
  member!: Member;

  @Prop()
  isRoot!: boolean;

  @Prop()
  showAll!: boolean;

  @Prop()
  hideAll!: boolean;

  @Prop()
  disableShowHideAll!: Function;

  @Prop()
  openEditTreeModal!: Function;

  @Prop()
  closeEditTreeModal!: Function;

  @Prop()
  rerenderTree!: Function;

  @Prop()
  shouldHighlightBranch!: Boolean;

  hideChildren =
    (this.showAll &&
      this.$store.state.idsOfParentsOfChildrenToHide.has(this.member.id)) ||
    (this.hideAll &&
      !this.$store.state.idsOfParentsOfChildrenToShow.has(this.member.id));

  topBorderWidth = "0px";
  topBorderLeft = "0px";

  spouseConnectorBarWidth = "100px";
  spouseConnectorBarLeft = "100px";

  isMemberInRelationshipTree: boolean = false;

  mounted() {
    this.calculateTopBarStyle();
    this.calculateSpouseConnectorBarWidth();
    this.calculateTopBarStylesForChildren();
  }

  calculateSpouseConnectorBarWidth() {
    const spouseContainers = this.$el.querySelectorAll(
      ".spouse-container>.member-container"
    );
    if (spouseContainers.length > 0) {
      const memberRight = this.$el
        .querySelectorAll(".spouses-container>.member-container")[0]
        .getBoundingClientRect().right;

      const spouseContainerLeft =
        spouseContainers[0].getBoundingClientRect().left;
      this.spouseConnectorBarWidth = spouseContainerLeft - memberRight + "px";
    }
  }

  calculateTopBarStyle() {
    // Refers to children nodes under current node
    const treeChildren = this.$el.querySelectorAll(
      `.${this.sanitizeString(
        this.member.name
      )}-child>.node-container>.node .member-container img`
    );

    // Only show a bar connecting children if more than one child
    if (treeChildren.length > 1) {
      const memberContainer = this.$el.querySelectorAll(
        `.${this.sanitizeString(this.member.name)}-child>.node-container>.node`
      )[0];
      const verticalBars = this.$el.querySelectorAll(
        `.${this.sanitizeString(
          this.member.name
        )}-child>.node-container>.node>.vertical-bar`
      );
      const firstVerticalBarLeft = verticalBars[0].getBoundingClientRect().left;
      const lastVerticalBarLeft =
        verticalBars[verticalBars.length - 1].getBoundingClientRect().left;

      // Calculate width of top border, which serves as connection line between siblings
      // Calculate width by finding difference in position of last vertical bar and first vertical bar
      this.topBorderWidth = lastVerticalBarLeft - firstVerticalBarLeft + "px";

      // Calculate left position of top border by subtracting position of first vertical bar of children by container of children
      this.topBorderLeft =
        firstVerticalBarLeft -
        memberContainer.getBoundingClientRect().left +
        "px";
    }
  }

  buildCurrentNodeId(currentMemberName: string): string {
    return `${currentMemberName}-node-id`;
  }

  buildChildNodeId(childMemberName: string): string {
    return `${childMemberName}-child-node-id`;
  }

  calculateTopBarStylesForChildren() {
    this.member.children.forEach((child) => {
      this.calculateTopBarStylesForChild(this.member, child);
    });
  }

  /**
   * Calculates the width of the top border for the child of any member
   * The width spans from the stem (vertical line) below the parent to the stem above the child
   * @param child child for home top border width needs to be created
   */
  calculateTopBarStylesForChild(parent: Member, child: Member) {
    var parentElement = this.$refs[
      this.buildCurrentNodeId(parent.name)
    ] as any[];
    var childElement = this.$refs[this.buildChildNodeId(child.name)] as any[];

    if (!parentElement || !childElement) return;

    var childElementValue = childElement[0]?.$el;

    var topBarWidth =
      this.getCenterPositionOfElement(parentElement) -
      this.getCenterPositionOfElement(childElementValue);

    var topBarOffset = this.getTopBarLeft(childElementValue);

    if (!childElement[0]) return;

    var childStyle =
      childElement[0].$el.getElementsByClassName("node")[0].style;

    childStyle.setProperty("--top-bar-width", Math.abs(topBarWidth) + "px");
    childStyle.setProperty(
      this.shouldSetTopBarLeft(parentElement, childElementValue)
        ? "--top-bar-left"
        : "--top-bar-right",
      topBarOffset + "px"
    );
  }

  getCenterPositionOfElement(el: any): number {
    if (el == null) return 0;
    const boundingRect = el.getBoundingClientRect();
    const halfOfWidth = boundingRect.width / 2;
    return boundingRect.left + halfOfWidth;
  }

  getTopBarLeft(el: any): number {
    if (!el) return 0;
    const boundingRect = el.getBoundingClientRect();
    return this.getCenterPositionOfElement(el) - boundingRect.left;
  }

  shouldSetTopBarLeft(parentEl: any, childEl: any): boolean {
    return (
      this.getCenterPositionOfElement(parentEl) >
      this.getCenterPositionOfElement(childEl)
    );
  }

  sanitizeString(str: string) {
    return str.replace(/ /g, "_");
  }

  toggleHideChildren() {
    this.$store.commit("toggleHideChildren", this.member.id);
    this.rerenderTree();
  }

  @Watch("$store.state.membersInRelationshipTree")
  updateMemberInRelationshipTree() {
    this.isMemberInRelationshipTree =
      this.$store.state.membersInRelationshipTree.has(this.member);
  }

  hasChildInRelationshipTree(): Boolean {
    for (var child of this.member.children) {
      if (this.$store.state.membersInRelationshipTree.has(child)) {
        return true;
      }
    }
    return false;
  }
}
export default Node;
</script>

<style lang="scss" scoped>
.node-container {
  --selected-for-relationship-tree-border-width: 5px;
  --selected-for-relationship-tree-border-color: lightblue;
  .tree-children {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .node {
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        content: "";
        border-top: 1px solid black;
        width: var(--top-bar-width);
        left: var(--top-bar-left);
        right: var(--top-bar-right);
      }
    }

    .highlight-branch {
      &::before {
        border-top: var(--selected-for-relationship-tree-border-width) solid
          var(--selected-for-relationship-tree-border-color);
        z-index: 9999;
      }
      .vertical-bar.top {
        width: var(--selected-for-relationship-tree-border-width);
        background-color: var(--selected-for-relationship-tree-border-color);
        z-index: 9999;
      }
    }

    .highlight-bottom-stem {
      .vertical-bar.bottom {
        width: var(--selected-for-relationship-tree-border-width);
        background-color: var(--selected-for-relationship-tree-border-color);
        z-index: 9999;
      }
    }
  }

  .node {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 0;
    min-height: 150px;
    min-width: 100px;
    --vertical-bar-height: 20px;

    .vertical-bar {
      position: absolute;
      height: var(--vertical-bar-height);
      width: 1px;
      background-color: black;
      left: 50%;

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }
    }

    .family-member-container {
      display: inline;
    }
    .spouses-container {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: fit-content;

      margin: auto;
      margin-left: 50%;

      .spouse-connector-bar {
        position: absolute;
        top: 50%;
        width: var(--spouse-connector-bar-width);
        transform: translateX(calc(150% + 1px));
        height: 1px;
        background-color: black;
        z-index: 5;
      }

      .spouse-container {
        margin-left: 50px;
      }

      .spouse-container ~ .spouse-container {
        margin-left: unset;
      }

      .member-container {
        &:first-child {
          transform: translateX(-50%);
        }
        + .member-container {
          margin-left: 0px;
        }
      }
    }
  }
  + .node-container {
    margin-left: 200px;
  }
}
</style>
