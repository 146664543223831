<template>
  <div id="home" v-if="receivedData">
    <show-relationship-panel />
    <div>
      <button @click="toggleShowHideAll">Show/Hide All</button>
      <button @click="openBulkUploadModal">Bulk Upload</button>
    </div>

    <h1>Hashmi Family Tree</h1>
    <ul id="tree-root">
      <node
        :member="rootMember"
        :isRoot="true"
        :openEditTreeModal="openEditTreeModal"
        :rerenderTree="rerenderTree"
        :key="key"
        :showAll="showAll"
        :hideAll="hideAll"
      ></node>
    </ul>
    <edit-tree-modal
      v-if="displayEditTreeModal"
      :option="modalOption"
      :member="modalMember"
      :closeEditTreeModal="closeEditTreeModal"
    />
    <bulk-upload-modal
      v-if="displayBulkUploadModal"
      :closeModal="closeBulkUploadModal"
    />
  </div>
</template>
 
<script lang="ts">
import Node from "./Node.vue";
import EditTreeModal from "./EditTreeModal.vue";
import BulkUploadModal from "./BulkUploadModal.vue";
import { Component, Vue } from "vue-property-decorator";
import Member from "../models/Member";
import ShowRelationshipPanel from "./ShowRelationshipPanel.vue";

@Component({
  name: "Home",
  components: {
    Node,
    EditTreeModal,
    BulkUploadModal,
    ShowRelationshipPanel,
  },
})
export default class Home extends Vue {
  showAll = true;
  hideAll = false;

  receivedData = false;
  rootMember!: Member;
  displayEditTreeModal = false;
  displayBulkUploadModal = false;

  modalOption?: number;
  modalMember?: Member;

  key = 0;

  mounted() {
    fetch(`${this.$store.state.serverAddress}/family-tree`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.rootMember = res;
        this.receivedData = true;
        this.$store.commit("setRootMember", this.rootMember);
      })
      .catch((e) => {
        console.log("Ran into error");
        console.log(e);
      });
  }

  toggleShowHideAll() {
    this.showAll = !this.showAll;
    this.hideAll = !this.showAll;
    if (this.showAll) {
      this.$store.state.idsOfParentsOfChildrenToHide.clear();
      this.$store.state.idsOfParentsOfChildrenToShow.clear();
    }
    this.rerenderTree();
  }

  openBulkUploadModal() {
    this.displayBulkUploadModal = true;
  }

  closeBulkUploadModal() {
    this.displayBulkUploadModal = false;
  }

  rerenderTree() {
    // incrementing key in order to re-render entire tree
    this.key++;
  }

  openEditTreeModal(option: number, member: Member) {
    this.modalOption = option;
    this.modalMember = member;
    this.displayEditTreeModal = true;
  }

  closeEditTreeModal() {
    this.displayEditTreeModal = false;
  }
}
</script>

<style lang="scss" scoped>
#tree-root {
  width: fit-content;
  margin: auto;
}
</style>
