export default class Member {
    id: number;
    name: string;
    children: Array<Member> = [];
    spouses: Array<Member> = [];
    birthday?: Date;
    dateOfDeath?: Date;
    description?: string;
    constructor(id: number, name: string) {
        this.id = id;
        this.name = name
    }
}