<template>
  <div
    class="member-container"
    :class="{
      'member-in-relationship-tree': isMemberInRelationshipTree,
    }"
  >
    <div>
      <img
        src="https://www.nacdnet.org/wp-content/uploads/2016/06/person-placeholder.jpg"
      />
      <p>{{ member.name }}</p>
    </div>
    <div class="options-container">
      <button v-if="!isSpouse" @click="() => openEditTreeModal(0, member)">
        Add parent
      </button>
      <button v-if="!isSpouse" @click="() => openEditTreeModal(1, member)">
        Add spouse
      </button>
      <button v-if="!isSpouse" @click="() => openEditTreeModal(2, member)">
        Add child
      </button>
      <button
        v-if="!isSpouse && member.children && member.children.length > 1"
        @click="() => openEditTreeModal(4, member)"
      >
        Reorder children
      </button>
      <button @click="() => openEditTreeModal(3, member)">Rename</button>
      <button
        :disabled="
          (member.children && member.children.length > 0) ||
          (member.childrenIds && member.childrenIds.length > 0) ||
          (!isSpouse && member.parents && member.parents.length > 0) ||
          (!isSpouse && member.spouses && member.spouses.length > 0)
        "
        @click="() => deleteUser()"
      >
        Delete
      </button>
      <button
        v-if="member.children && member.children.length > 0"
        @click="toggleHideChildren"
      >
        Show/Hide Children
      </button>
      <button v-if="!isSpouse" @click="addToShowRelationshipList">
        View Relationship Line
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Member from "../models/Member";

@Component({
  name: "MemberNode",
})
export default class MemberNode extends Vue {
  @Prop()
  member!: Member;

  @Prop()
  openEditTreeModal!: Function;

  @Prop()
  isSpouse!: boolean;

  @Prop()
  toggleHideChildren!: Function;

  @Prop()
  isMemberInRelationshipTree!: boolean;

  deleteUser() {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        memberId: this.member.id,
      }),
    };
    fetch(this.$store.state.serverAddress + "/delete", options).then(() =>
      location.reload()
    );
  }

  addToShowRelationshipList() {
    this.$store.commit("addMemberToDrawRelationshipFor", this.member);
  }
}
</script>

<style lang="scss" scoped>
.member-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 150px;
  height: 200px;
  margin: 15px auto;
  border: 1px solid black;

  &.member-in-relationship-tree {
    background-color: lightblue;
  }

  img {
    width: 80%;
    // height: 75px;
    // width: 75px;
    margin: 0 auto;
  }
  p {
    margin: 0;
    margin-top: 15px;
  }

  &:hover {
    .options-container {
      animation: fadeIn 0.2s linear forwards;
    }
  }

  .options-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(110, 101, 101, 0.527);
    opacity: 0.5;
    animation: fadeOut 0.2s linear forwards;

    button {
      width: 80%;
      margin: auto;
      padding: 5px;
      background-color: lightblue;
      border: none;
      box-shadow: 0px 0px 6px white;
      cursor: pointer;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
