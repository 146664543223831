import Vue from "vue";
import App from "./App.vue";
import Vuex from 'vuex';
import Member from "./models/Member";


const port = 3002;

// for personal machine development
// const serverAddress = `http://localhost:${port}`

// for deployment
const serverAddress = `https://hashmi.site:${port}`

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    serverAddress,

    rootMember: new Member(-1, ""),
    idsOfParentsOfChildrenToHide: new Set(),
    idsOfParentsOfChildrenToShow: new Set(),

    // Used to keep track of members selected that we want to see relationship between
    membersToDrawRelationshipFor: new Set(),
    membersInRelationshipTree: new Set(),
  },

  mutations: {
    setRootMember(state, member: Member) {
      state.rootMember = member
    },

    toggleHideChildren(state, idOfParent) {
      state.idsOfParentsOfChildrenToHide.has(idOfParent) ? state.idsOfParentsOfChildrenToHide.delete(idOfParent) : state.idsOfParentsOfChildrenToHide.add(idOfParent)
      state.idsOfParentsOfChildrenToShow.has(idOfParent) ? state.idsOfParentsOfChildrenToShow.delete(idOfParent) : state.idsOfParentsOfChildrenToShow.add(idOfParent)
    },

    addMemberToDrawRelationshipFor(state, member: Member) {
      state.membersToDrawRelationshipFor = new Set(state.membersToDrawRelationshipFor.add(member))
    },

    removeMemberToDrawRelationshipFor(state, member: Member) {
      state.membersToDrawRelationshipFor.delete(member);
      state.membersToDrawRelationshipFor = new Set(state.membersToDrawRelationshipFor);
    },

    setMembersInRelationshipTree(state, membersInRelationshipTree: Set<Member>) {
      state.membersInRelationshipTree = membersInRelationshipTree;
    }
  }
})


Vue.config.productionTip = false;

new Vue({
  store: store,
  render: h => h(App)
}).$mount("#app");
