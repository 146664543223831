<template>
  <div class="person-container">
    <span class="person-name-text">{{ member.name }}</span>
    <button class="remove-person-button" @click="removeMember">X</button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Member from "../models/Member";

@Component({
  name: "ShowRelationshipPanelEntry",
})
export default class ShowRelationshipPanelEntry extends Vue {
  @Prop()
  member!: Member;

  removeMember() {
    this.$store.commit("removeMemberToDrawRelationshipFor", this.member);
  }
}
</script>

<style lang="scss" scoped>
.person-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  .remove-person-button {
    border: none;
    background: none;
    color: Red;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      background-color: lightgrey;
    }
  }
}
</style>